<!--
 * @Author: John
 * @Date: 2022-02-05 12:59:43
 * @LastEditTime: 2022-05-07 18:08:00
 * @LastEditors: John
 * @Description: 重置密码
 * @FilePath: \jjms_ui\src\views\toResetPwd.vue
 * @Copyright John
-->
<template>
    <div id="wrap">
        <div class="logo-box">
            <img src="@/assets/img/title_logo2.png" alt="">
        </div>
        <el-card class="box-card login-panel">
            <div slot="header" class="clearfix">
                <h2 style="text-align: center">重置密码</h2>
            </div>
            <el-form ref="toResetForm" :model="formData" :rules="formRules" status-icon label-width="100px">
                <el-form-item label="邮箱：" prop="email">
                    <el-input v-model="formData.email" prefix-icon="el-icon-message" placeholder="请输入绑定的邮箱"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit('toResetForm')">重置密码</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <div class="footer-bar">
            <footer-bar />
        </div>
    </div>
</template>
<script>
import api from '../api/req_api'
import footerBar from '../components/footer-bar.vue'
export default {
    components: {
        footerBar
    },
    data(){
        let validateEmail = (rule, value, callback) => {
            let emailReg = /\w+@\w+\.(com|co|cn|org)/g;
            if(!emailReg.test(value)) {
                callback(new Error('请输入合法的邮箱地址!'));
                return;
            }
            callback();
        };
        return {
            formData: {
                email:'',
            },
            formRules: {
                email: [
                    {required: true, message: '电子邮箱是必填项!', trigger: 'blur'},
                    {validator: validateEmail, trigger: 'blur'}
                ]
            }
        };
    },
    methods: {
        onSubmit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    api.sendResetPwdEmail(this.formData.email)
                        .then(data => {
                            if(data != null) {
                                this.$message({message: "重置密码链接已发送到邮箱，请尽快登录邮箱完成重置密码操作!", type: 'success'});
                            } 
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
}
</script>